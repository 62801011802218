





















































































import {Component} from 'vue-property-decorator';
import {
  dispatchDeleteBiomarkerResult,
  dispatchGetBiomarkerResultsForList,
} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component({components: {ConfirmationModal}})
export default class AdminBiomarkerResult extends AppComponent {
  public headers = [
    {
      text: 'Quest Biomarker code',
      sortable: true,
      value: 'questBiomarkerId',
      align: 'left',
    },
    {
      text: 'Visit Id',
      sortable: true,
      value: 'visitId',
      align: 'left',
    },
    {
      text: 'Date of Service',
      sortable: true,
      value: 'dateOfService',
      align: 'left',
    },
    {
      text: 'Test result',
      sortable: true,
      value: 'testResult',
      align: 'left',
    },
    {
      text: 'Measurement units',
      sortable: true,
      value: 'measurementUnits',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingBiomarkerData = false;

  public mounted() {
    this.fetchData();
  }

  public async fetchData() {
    this.fetchingBiomarkerData = true;
    await this.$nextTick();
    await dispatchGetBiomarkerResultsForList(this.$store);
    this.fetchingBiomarkerData = false;
  }

  /**
   * Delete a biomarker results and refresh the screen
   */
  public async deleteBiomarkerResults(biomarkerResultId: string) {
    await dispatchDeleteBiomarkerResult(this.$store, {biomarkerResultId});
    this.$forceUpdate();
  }
}
