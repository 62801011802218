var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',{staticClass:"display-1 header--text"},[_vm._v(" Manage Biomarker results ")]),_c('v-spacer')],1),(_vm.fetchingBiomarkerData)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.biomarkerResults},scopedSlots:_vm._u([{key:"item.dateOfService",fn:function(props){return [_vm._v(" "+_vm._s(props.item.dateOfService)+" ")]}},{key:"item.testResult",fn:function(props){return [_vm._v(" "+_vm._s(props.item.testResult)+" ")]}},{key:"item.visitId",fn:function(props){return [(props.item.visitId)?_c('router-link',{attrs:{"target":"_blank","to":{name: 'main-admin-visits-edit', params: {visitId: props.item.visitId}}}},[_vm._v(" "+_vm._s(props.item.visitId)+" ")]):_vm._e()]}},{key:"item.measurementUnits",fn:function(props){return [_vm._v(" "+_vm._s(props.item.measurementUnits)+" ")]}},{key:"item.id",fn:function(props){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                  name: 'main-admin-biomarkerresults-edit',
                  params: {biomarkerResultId: props.item.id},
                },"color":"accent"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"dark"}},[_vm._v("edit")]),_vm._v(" Edit ")],1)],1),_c('ConfirmationModal',{attrs:{"buttonLabel":"Delete","listItem":"","modalWidth":450,"confirmationLabel1":("Are you sure you want to delete the " + (props.item.questBiomarkerId ||
                    '') + " biomarker result?"),"confirmationLabel2":"This biomarker result will be removed from the visits in which it is associated.","cancelButtonLabel":"Cancel","confirmButtonLabel":"Delete"},on:{"confirm":function($event){return _vm.deleteBiomarkerResults(props.item.id)}}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }